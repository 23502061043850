.head .left, .head .right {
    display: flex;
    gap: 30px;
    flex-direction: row;
    align-items: center;
}

.head p {
    color: rgb(0, 0, 0);
    font-family: Manrope, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
}



.main .body .table {
    margin-top: 50px;
}

.main .body .table .tableHead {
    display: flex;
    flex-direction: row;
}

.main .body .table .tableRow {
    display: flex;
    flex-direction: row;
}

.main .body .table .tableBody {
    display: flex;
    flex-direction: column;
}

.main .body .table .tableColumn {
    width: 30%;
    padding: 16px 32px;
}

.main .body .table .tableColumn p {
    color: rgb(0, 0, 0);
    font-family: Manrope, serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
}

.main .body .table .tableBody .tableColumn {
    box-sizing: border-box;
    border-top: 1px solid rgb(231, 235, 237);
    border-bottom: 1px solid rgb(231, 235, 237);
    padding: 16px 32px;
}

.main .body .tableInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
}

.main .body .tableInfo h1 {
    color: rgb(106, 106, 106);
    font-family: Manrope, serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0;
    text-align: left;
}

.main .body .tableBody .tableColumn span {
    color: rgb(164, 165, 166);
    font-family: Manrope, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
}

.main .body .tableBody .tableRow {
    align-items: center;
}

.main .body .tableBody .tableColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70px;
}

._checked {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: start !important;
    gap: 10px;
}

._checkedDot {
    width: 10px;
    background: rgb(0, 151, 24);
    height: 10px;
    border-radius: 50%;
}

.main .body .table .tableBody select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 6px;
    padding: 13px 16px;
    color: rgb(106, 106, 106);
    font-family: Manrope, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1px;
    text-align: left;
    cursor: pointer;
}


.green {
  color: green;
}

.orange {
  color: orange;
}


.copy-message {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
  text-align: center;
}

.highlight {
  background-color: lightgray;
  transition: background-color 0.5s ease-out;
}


