body {
    margin: 0;
    padding: 0;
}

* {
    margin: 0;
}

.wrap {
    padding: 36px;
}

.head h1 {
    color: rgb(0, 0, 0);
    font-family: Manrope;
    font-size: 32px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.head button {
    color: rgb(195, 2, 2);
    font-family: Manrope;
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-align: left;
    padding: 11px 18px;
    box-sizing: border-box;
    border: 1px solid rgb(195, 2, 2);
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
}