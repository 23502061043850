* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.content {
  display: inline-block;
}

.checkmark-circle {
  width: 100px;
  height: 100px;
  background-color: #FF0000; /* Red color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}

.checkmark {
  width: 50px;
  height: 50px;
  position: relative;
}

.checkmark::before,
.checkmark::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 50px;
  background-color: white;
  top: 0;
  left: 22px;
}

.checkmark::before {
  transform: rotate(45deg);
}

.checkmark::after {
  transform: rotate(-45deg);
}

h1 {
  font-size: 24px;
  color: #333;
}

.signin-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #0077bb;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.signin-button:hover {
  background-color: #005f99;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
