.wrap {
  /* Your styles */
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters input {
  padding: 13px 16px;
  box-sizing: border-box;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 6px;
  color: rgb(134, 132, 132);
  font-family: Manrope;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-align: left;
}

.filters {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

._big {
  width: 50%;
}

._small {
  width: 25%;
}

.offers {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.offer {
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  background: rgb(255, 255, 255);
}

.offer .offerHead {
  display: flex;
  flex-direction: row;
}

.offer .offerHead .offerColumn {
  width: 20%;
}

.offer .offerHead .offerColumn p {
  color: rgb(0, 0, 0);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}

.offer .offerHead .data {
  width: 55% !important;
  justify-content: start !important;
}

.offer .offerHead .offerColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgb(231, 235, 237);
  border-bottom: 1px solid rgb(231, 235, 237);
  padding: 12px 20px;
}

.offer .tableRow .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgb(231, 235, 237);
  border-bottom: 1px solid rgb(231, 235, 237);
  padding: 12px 20px;
  width: 5%;
}

.offer .tableRow .left h2 {
  color: rgb(0, 0, 0);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.offer .tableRow .left p {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: rgb(106, 106, 106);
}

.offer .tableRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.offer .tableRow .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.offer .tableRow .right .tableColumn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 20px;
  gap: 10px;
  border-right: 1px solid rgb(231, 235, 237);
  width: 25%;
}

.offer .tableRow .right .row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(231, 235, 237);
  width: 100%;
}

.offer .tableRow .right .tableColumn p {
  color: rgb(0, 0, 0);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.offer .tableRow .right .tableColumn span {
  color: rgb(164, 165, 166);
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.offer .tableRow .right .link {
  width: 48.7%;
  justify-content: start !important;
}

.offer .offerData .tableRow .left span {
  color: rgb(43, 170, 32);
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}

th, td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  height: 25px;
}

td {
  min-width: 80px;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2;
  border-right: 1px solid #ccc;
}

.sticky-col:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #ccc;
}

.second-sticky-col {
  position: -webkit-sticky;
  position: sticky;
  left: 189px;
  background-color: white;
  z-index: 2;
  border-right: 1px solid #ccc;
}

.second-sticky-col:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #ccc;
}

.offer-title {
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: white;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.date-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  border-bottom: 1px solid #ccc;
}

.number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.up {
  border-radius: 4px;
  background: rgb(237, 251, 240);
  padding: 4px 8px;
}

.up {
  color: rgb(43, 170, 32) !important;
  font-size: 14px !important;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}



.down {
  border-radius: 4px;
  background: rgb(251, 238, 237);
  padding: 4px 8px;
}

.down {
  color: rgb(170, 34, 32) !important;
  font-size: 14px !important;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.undefined {
  border-radius: 4px;
  background: rgb(238, 238, 238);
  padding: 4px 8px;
}

.undefined {
  color: rgb(128, 128, 128) !important;
  font-size: 14px !important;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.small-gray-link {
  font-size: 12px;
  color: gray;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
